import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '@core/http/http.service';
import { environment } from '@env';
import { Account } from '@modules/admin/account/account';
import { FirstLoginRequest } from '@modules/authentication/domain/firstLoginRequest';
import { User } from '@shared/user-data/entity/User';
import { rejects } from 'assert';
import { Observable, Observer, Subject } from 'rxjs';
import { UserData } from './user-data';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    userData: UserData = undefined;
    loggedIn: boolean = false;


    private AUTH_REDIRECT = '/auth/login';
    private loginURL: string = environment.hostURL + "/auth/login";
    private refreshURL: string = environment.hostURL + "/auth/refresh";
    private registerURL: string = environment.hostURL + "/auth/registration";
    private userUpdateURL: string = environment.hostURL + "/api/user";
    private userURL: string = environment.hostURL + "/api/user/";
    private changeOnFirstLogin: string = environment.hostURL + "/auth/change";

    constructor(private router: Router, private httpService: HttpClient, private customHttp: HttpService) {
        //this.loadUserData.then();
    }

    public changePasswordOnFirstLogin = (request: FirstLoginRequest) => {
        return this.customHttp.doPost<boolean>(this.changeOnFirstLogin, request);
    }

    public register = (account: Account, successFunction: Function, errorFunction: Function) => {
        this.customHttp.doPost<Account>(this.registerURL, account).subscribe(
            account => {
                successFunction(account);
                this.router.navigate(['/auth/login']);
            },
            error => {
                errorFunction(error.error);
            }
        )
    }

    public updateUserData = (user: User, successFunction: Function, errorFunction: Function) => {
        this.customHttp.doPut<User>(this.userUpdateURL + user.id, user).subscribe(
            user => {
                successFunction(user);
            },
            error => {
                errorFunction(error.error);
            }
        )
    }

    public getUser = (id: string) => {
        return this.customHttp.doGet<User>(this.userURL + id);
    }

    private loadUserData = new Promise<void>((resolve) => {
        this.httpService.post<UserData>(this.refreshURL, null).subscribe(
            resp => {
                this.updateData(resp);
                this.onAuthStatusChange.next();
                resolve();
            },
            error => {
                console.log(error.error.error);
                this.onAuthStatusChange.next();
                resolve();
            }
        )
    })

    private loginWithUserNameAndPassword = (login: string, password: string) => {
        return this.httpService.post<UserData>(this.loginURL, {
            "username": login,
            "password": password,
            "supportCookie": true
        });
    }

    private updateData = (resp: UserData) => {
        this.userData = resp;
        this.loggedIn = (this.userData !== undefined);
        localStorage.setItem('AUTH_TOKEN', this.userData.token);
    }

    private loadAuthorities = new Observable<string[]>((observer: Observer<string[]>) => {
        if (this.userData === undefined || this.userData.authorities === undefined) {
            this.loadUserData.then(() => {
                if (this.userData === undefined || this.userData.authorities === undefined) {
                    observer.next([]);
                } else {
                    observer.next(this.userData.authorities);
                }
            })
        } else {
            observer.next(this.userData.authorities);
        }
    });

    private checkAuthentication = new Observable<boolean>((observer: Observer<boolean>) => {
        if (this.userData === undefined) {
            this.loadUserData.then(() => {
                observer.next(this.loggedIn);
            });
        } else {
            observer.next(this.loggedIn);
        }
    });

    isAuthenticated$ = this.checkAuthentication;
    athorities$ = this.loadAuthorities;
    onAuthStatusChange = new Subject();

    redirectToLogin() {
        this.logout();
        
        //this.router.navigateByUrl(this.AUTH_REDIRECT);
    }

    login(login: string, password: string, errorCB: Function): Observable<UserData> {
        let data = new Subject<UserData>();
        this.loginWithUserNameAndPassword(login, password).subscribe(
            resp => {
                this.updateData(resp);
                this.onAuthStatusChange.next();
                if (this.userData.changeOnFirstLogin) {
                    data.next(this.userData);
                } else {
                    this.router.navigate(['/workplace']);
                }
            },
            error => {
                errorCB.apply(this, error);
            }
        );
        return data.asObservable();

    }

    logout() {
        this.userData = undefined;
        this.loggedIn = false;
        localStorage.removeItem('AUTH_TOKEN');
        window.location.href = this.AUTH_REDIRECT;
    }



}