import { Pipe, PipeTransform } from '@angular/core';
import { PropertyService } from './property.service';

@Pipe({name: 'i18n'})
export class PropertyPipe implements PipeTransform {
    constructor(private propertyService: PropertyService) {
    }
    transform(property: any): any {
        return this.propertyService.get(property);
    }
}