import { NgModule } from '@angular/core';
import { PropertyPipe } from './property.pipe';
import { PropertyService } from './property.service';


@NgModule({
    imports: [],
    exports: [PropertyPipe],
    declarations: [PropertyPipe],
    providers: [PropertyService],
})
export class PropertyModule { }
