import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'tco-welcome-layout',
    templateUrl: 'welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})

export class WelcomeLayoutComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}