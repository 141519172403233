import { ViewCompileResult } from '@angular/compiler/src/view_compiler/view_compiler';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth.service';

@Directive({ selector: '[hasAuthority]' })
export class HasAuthorityDirective {
    private hasView = false;
    private authorities = [];
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {
        this.authService.onAuthStatusChange.subscribe(() => {
            this.isHasAuthority(this.authorities);
        });
    }

    @Input() set hasAuthority(authorities: string[]) {
        if (typeof authorities === 'string') {
            authorities = [authorities];
        }
        this.authorities = authorities;
        this.isHasAuthority(authorities);
    }

    private isHasAuthority = (authorities: string[]) => {
        this.authService.athorities$.subscribe((userAuthorities) => {
            let condition = this.hasAny(userAuthorities, authorities);
            if (condition && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!condition && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        })
    }

    private hasAny = (all: string[], authorities: string[]) => {
        for (let authority of authorities) {
            if (!authority.endsWith("_PRIVILEGE")) {
                authority += "_PRIVILEGE";
            }
            if (all.indexOf(authority) > -1) {
                return  true;
            }
        }
        return false;
    }
}