<div nz-row>
    <div class="welcome" nz-col nzSpan="8" nzOffset="8">
        <div class="inner-box">
            <div class="welcome-login">
                <h1>{{'global.welcome.login.title' | i18n}}</h1>
                <button class = "welcome-button" nz-button nzType="primary"
                    [routerLink]="['/auth/login']">{{'global.welcome.login.button'  | i18n}}</button>
            </div>
            <!-- <div class="welcome-register">
                <h1>{{'global.welcome.register.title' | i18n}}</h1>
                <button class = "welcome-button" nz-button nzType="primary"
                    [routerLink]="['/auth/register']">{{'global.welcome.register.button'  | i18n}}</button>
            </div> -->
        </div>
    </div>
</div>