import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PropertyService } from '@core/property/property.service';
import { AuthGuard } from '@core/security/auth.guard';
import { WelcomeLayoutComponent } from './layout/welcome/welcome.component';

const routes: Routes = [
    {
        path: '',
        component: WelcomeLayoutComponent
    },
    {
        path: 'admin',
        data: {
            breadcrumb: PropertyService.get("admin.breadcrumb")
        },
        loadChildren: () =>
        import('@modules/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('@modules/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'workplace',
        canActivate: [AuthGuard],
        data: {
            breadcrumb: PropertyService.get("workplace.breadcrumb")
        },
        loadChildren: () =>
            import('@modules/workplace/workplace.module').then(m => m.WorkplaceModule)
    },
    {
        path: 'page-builder',
        canActivate: [AuthGuard],
        data: {
            breadcrumb: PropertyService.get("page-builder.breadcrumb")
        },
        loadChildren: () =>
            import('@modules/page-builder/page-builder.module').then(m => m.PageBuilderModule)
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }