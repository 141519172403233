import { Injectable } from '@angular/core';
import * as translationJSON from '../../../assets/i18n/ua.json';

@Injectable({
    providedIn: "root"
})
export class PropertyService {

    private file$;

    constructor() {
        this.file$ = translationJSON;
    }

    /**
    * // TODO: Fix It
    */
    public get(key: string) {
        let attributes: string[] = key.split(".");
        let next = translationJSON["default"];
        attributes.forEach(attribute => {
            if (next === undefined) {
                console.log(key);
                return key;
            }
            next = next[attribute];
            if (next === undefined) {
                console.log(key);
            }
        });
        if (typeof next == "string") {
            return next;
        } else {
            return key;
        }
    }

    public static get(key: string) {
        let attributes: string[] = key.split(".");
        let next = translationJSON["default"];
        attributes.forEach(attribute => {
            if (next === undefined) {
                console.log(key);
                return key;
            }
            next = next[attribute];
            if (next === undefined) {
                console.log(key);
            }
        });
        if (typeof next == "string") {
            return next;
        } else {
            return key;
        }
    }
}