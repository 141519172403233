import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertyService } from '@core/property/property.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(
        private httpClient: HttpClient,
        private notification: NzNotificationService ) {
    }

    public doGet<T>(url: string, params?: HttpParams): Observable<T> {
        return this.httpClient.get<T>(url, this.getOptions(params)).pipe(catchError(errorResponse => {
            this.handleError(errorResponse, url);
            return new Observable<never>();
        }));
    }

    public doPost<T>(url: string, object: any, params?: HttpParams): Observable<T> {
        return this.httpClient.post<T>(url, object, this.getOptions(params)).pipe(catchError(errorResponse => {
            this.handleError(errorResponse, url);
            return new Observable<never>();
        }));
    }

    public doPut<T>(url: string, object: any, params?: HttpParams): Observable<T> {
        return this.httpClient.put<T>(url, object, this.getOptions(params)).pipe(catchError(errorResponse => {
            this.handleError(errorResponse, url);
            return new Observable<never>();
        }));
    }

    public doDelete<T>(url: string, params?: HttpParams): Observable<T> {
        return this.httpClient.delete<T>(url, this.getOptions(params)).pipe(catchError(errorResponse => {
            this.handleError(errorResponse, url);
            return new Observable<never>();
        }));
    }

    private getOptions(params: HttpParams) {
        if (params === undefined) {
            return {};
        } else {
            return {
                params: params
            }
        }
    }

    private handleError = (errorResponse, url) => {
        this.notification.error(PropertyService.get('global.error'), errorResponse.error.error);
        throw errorResponse;
    }

    public getClient = () => {
        return this.httpClient;
    }
}